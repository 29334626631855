/*
Custom styles to extend nifty admin theme
hopefully as few as possible
*/
.page-actions {
  text-align: right;
}

.cursor-move {
  cursor: move;
}


.panel-icon {
  position: absolute;
}

.entity-picker-modal .modal-body {
  padding:0;
}
.entity-picker-modal .modal-dialog {
  margin: 15px;
}
.entity-picker-frame {
  border:0;
  width:100%;
  height:100%;
  min-height: 80vh;
  display: block;
  margin: 0 -1px;
}

.dashboard-menu-item > .media-left {
  transition: padding .1s ease-out;
}

.dashboard-menu-item > i {
  transition: padding .1s ease-out;
}

.dashboard-menu-item:hover > .media-left {
  padding-left: 8px;
}

.dashboard-menu-item:hover > i {
  padding-left: 4px;
}

/* NIFTY FIXES */
/* fix themify icons in buttons, might be fixed by nifty theme later */
[class^="ti-"]:before,
[class*=" ti-"]:before
{
  font-family: 'themify';
}
/* timeline stat pushed content of the timeline label slightly to the right */
.timeline-stat {
  margin-right: -50px;
}

/* timeline properties */
.timeline-property {
  @extend .timeline-time;
  display: block;
  text-align: center;
  margin: 5px auto 0;
  padding: 6px 0 5px;
}



.media-row {
  max-width: 1400px;
}

.fluid-img-wrapper {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 86%;
  position: relative;
}

.fluid-img-wrapper > .panel {
  margin-bottom: 0;
}

.fluid-img-wrapper > img {
  width: 100%;
  position: absolute;
  margin: 0 auto;
}

.fluid-img-wrapper > .panel-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 22%;
}

.fluid-img-wrapper .panel-icon {
  z-index: 1;
  background-color: rgba(100,100,100,0.7)
}

.fluid-folder-wrapper {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 86%;
  position: relative;
}

.fluid-folder-wrapper > .panel {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
}

.fluid-folder-wrapper .panel-body {
  margin-top: 35%;
  transform: translateY(-50%);
}

.fluid-folder-wrapper .panel-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 22%;
}

.modal-dialog .modal-body {
  overflow: hidden;
}

.table.table-hover img {
  max-height: 50px;
}

.menu-title + i.arrow {
  margin-top: -4px;
}

.timeline-label.invisible {
  opacity: 0.6;
  visibility: visible;
}

.navbar-top-links__single-item {
  margin-top: 12px;
  padding: 0 20px;
}

// helper classes
.img-preview {
  width: 200px;
}

// fix modals
.modal-dialog {
  margin: 30px auto;
}
.collapse__header{
  cursor:pointer;
}
.js-collapse{
  .ti-plus{
    display:none;
  }
}
.collapsed{
  .ti-minus{
    display: none;
  }
  .ti-plus{
    display: block;
  }
}

.collapseIcon {
  margin-top: 15px;
  position: absolute;
  right: 0;
  top: 0;
}
.searchbox .custom-search-form input{
  background: white;
}


/* MEDIA QUERIES */
@media (min-width: 768px) {
  .page-actions {
    display: table-cell;
    vertical-align: middle;
  }
}

/* overwrite nifty css */
.brand-icon {
  width: 32px;
  height: 32px;
  float: left;
  margin: 4px 5px;
}

.orderable-handle{
  &:hover {
    cursor:grab;
  }
}

.table-orderable {
  tr{
    transition: background-color .5s ease-in;

    &.bg-primary a{
      color: #758697;
    }
  }

  .drag-disabled .orderable-handle, .drag-disabled .orderable-handle:hover{
    cursor: default;
  }
}
