
.nested-form--inline {
  margin-right: -11px;
}

.nested-form--inline .nested-form__item {
  display: inline-block;
  margin-right: 10px;
}


.translation-form__header {
  min-height: 50px;
}

.uploaded-image {
  margin-bottom: 20px;
  max-height: 200px;
}


.form-group label.required:after {
  content: ' *';
  color: #a94442;
}
